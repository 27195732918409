<template>
  <component :is="categoryData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="categoryData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching event data
      </h4>
      <div class="alert-body">
        No course found with this event id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'dashboard-courses-list'}"
        >
          Courses List
        </b-link>
        for other courses.
      </div>
    </b-alert>
    <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
    >
      <b-form @submit.prevent="onUpdate">
      <b-row>
        <b-col md="6">
          <validation-provider
              #default="validationContext"
              name="English Title"
              rules="required"
          >
            <b-form-group
                label="Title En"
                label-for="mc-title-en"
            >
              <b-form-input
                  v-model="categoryData.title_en"
                  id="mc-title-en"
                  placeholder="Title En"
                  :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Title Ar"
              label-for="mc-title-ar"
          >
            <b-form-input
                v-model="categoryData.title_ar"
                id="mc-title-ar"
                placeholder="Title Ar"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <template v-for="(sub, sub_index) in categoryData.subs">
          <b-col md="4" style="margin-left: 5%">
            <b-form-group
                :label="'English Title For Sub Category #'+(sub_index+1)"
                :label-for="'category-english-sub-title'+(sub_index+1)"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="'English Sub Category Title #'+(sub_index+1)"
                  rules="required"
              >
                <b-form-input
                    :id="'category-english-sub-title'+(sub_index+1)"
                    v-model="sub.title_en"
                    placeholder="English Sub Title"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                :label="'Arabic Title For Sub Category #'+(sub_index+1)"
                :label-for="'category-arabic-sub-title'+(sub_index+1)"
            >
              <b-form-input
                  :id="'category-arabic-sub-title'+(sub_index+1)"
                  v-model="sub.title_ar"
                  placeholder="Arabic Sub Title"
              />
            </b-form-group>
          </b-col>
          <b-col
              lg="2"
              md="3"
              class="mb-50"
          >
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeSubCategory(sub_index)"
            >
              <feather-icon
                  icon="XIcon"
                  class="mr-25"
              />
              <span>Delete Sub</span>
            </b-button>
          </b-col>
        </template>
        <b-col md="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
              class="mr-1"
              @click="incrementSubs"
          >
            Add Sub Category
          </b-button>
          <br><br>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Submit
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
  </component>
</template>

<script>

import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import courseStoreModule from '../courseStoreModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BAlert,
  BFormRadio,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormGroup,
  BFormFile,
  BFormInput,
  BLink,
  BRow,
  BFormDatepicker,
  BFormTextarea
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";
import { required, alphaNum, email,url } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BFormRadio,
    BFormDatepicker,
    BCard,
    Cleave,
    BFormInvalidFeedback,
    BFormFile,
    BFormTextarea,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    vSelect,

    //Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  methods:{
    incrementSubs(){
      this.categoryData.subs.push({
        title_en: '',
        title_ar: ''
      })
    },
    removeSubCategory(sub_index) {
      this.categoryData.subs.splice(sub_index, 1)
    },
  },
  setup() {
    const blankCategoryData = {
      title_en: null,
      title_ar: null,
      is_active: true,
      speciality_id: null,
      description_en: null,
      description_ar: null,
      price: null,
      expire_date: null,
      expire_duration: null,
      expire_date_type: null,
      id: null,
      image: null,
      price_after_discount: null
    }
    const courseImageId = ref(null);
    const have_discount = ref(null);
    const expire_date_type = ref(null);
    const toast = useToast()

    const categoryData = ref(JSON.parse(JSON.stringify(blankCategoryData)))
    const resetCategoryData = () => {
      categoryData.value = JSON.parse(JSON.stringify(blankCategoryData))
    }

    const EVENT_APP_STORE_MODULE_NAME = 'courses'

    // Register module
    if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.registerModule(EVENT_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENT_APP_STORE_MODULE_NAME)
    })
    store.dispatch('courses/fetchCategory', {id: router.currentRoute.params.id})
        .then(response => {
          categoryData.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            categoryData.value = undefined
          }
        })

    const onUpdate = () => {
      let data = {
        title_en: categoryData.value.title_en,
        title_ar: categoryData.value.title_ar,
        subs: categoryData.value.subs
      }
      store.dispatch('courses/updateCategory', {
        id: router.currentRoute.params.id,
        data: data
      })
          .then(response => {
            router.push({name : 'dashboard-courses-list'})
            toast({
              component: ToastificationContent,
              props: {
                title: 'Category Updated Successfully',
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'success',
              },
            }, {
              position: 'top-center'
            })
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'error',
              },
            }, {
              position: 'top-center'
            })
          })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCategoryData)
    return {
      onUpdate,
      categoryData,
      courseImageId,
      have_discount,
      expire_date_type,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
